import * as am4core from '@amcharts/amcharts4/core'

import { generalConfig } from './generalConfig'

// TODO: Not in use?
const primary = {
  main: '#4389FD',
  dark: '#1A6DFB',
  light: '#F4FAFF',
  stroke: '#A1C4FE', // no color spec in figma palette
  contrastText: '#FFFFFF',
}

export const chartRainbowOpacityPalette = [
  am4core.color('rgba(67, 137, 253, 0.1)'),
  am4core.color('rgba(105, 56, 239, 0.1)'),
  am4core.color('rgba(221, 37, 144, 0.1)'),
  am4core.color('rgba(3, 152, 85, 0.1)'),
  am4core.color('rgba(217, 45, 32, 0.1)'),
  am4core.color('rgba(62, 71, 132, 0.1)'),
  am4core.color('rgba(220, 104, 3, 0.1)'),
  am4core.color('rgba(71, 84, 103, 0.1)'),
  am4core.color('rgba(68, 76, 231, 0.1)'),
  am4core.color('rgba(127, 86, 217, 0.1)'),
  am4core.color('rgba(227, 27, 84, 0.1)'),
  am4core.color('rgba(0, 134, 201, 0.1)'),
  am4core.color('rgba(236, 74, 10, 0.1)'),
]

const am4themes_rainbow: am4core.ITheme = (target: am4core.BaseObject): void => {
  if (target instanceof am4core.ColorSet) {
    target.list = [...chartRainbowOpacityPalette]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Navbar links, Zoom out button, Focus filter.

    target.setFor('primaryButton', am4core.color(primary.main))
    target.setFor('primaryButtonHover', am4core.color(primary.dark))
    // target.setFor('primaryButtonDown', am4core.color('primary.main'));
    target.setFor('primaryButtonActive', am4core.color(primary.main))
    target.setFor('primaryButtonText', am4core.color(primary.contrastText))
    target.setFor('primaryButtonStroke', am4core.color(primary.stroke))

    // Scrollbar, Map polygons, Scrollbar, and most of the buttons.

    target.setFor('secondaryButton', am4core.color(primary.main))
    target.setFor('secondaryButtonHover', am4core.color(primary.dark))
    // target.setFor('secondaryButtonDown', am4core.color('primary.main'));
    target.setFor('secondaryButtonActive', am4core.color(primary.main))
    target.setFor('secondaryButtonText', am4core.color(primary.contrastText))
    target.setFor('secondaryButtonStroke', am4core.color(primary.stroke))

    // Fill of any container, including main chart element, Axis break fill, XY Chart Scrollbar background, etc..
    target.setFor('background', am4core.color(primary.light))

    // Axis fills, full-width XY cursor, Gauge clock hand, Flow diagram links, Shadow filter, misc map controls, Navigation bar arrow, etc.
    target.setFor('alternativeBackground', am4core.color(primary.main))

    // Axis tooltip text, Scrollbar grip visual elements.
    target.setFor('alternativeText', am4core.color(primary.contrastText))
  }
  // Add general settings to the theme
  generalConfig(target)
}

export default am4themes_rainbow
