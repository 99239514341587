import * as am4core from '@amcharts/amcharts4/core'

// Forest theme colors
const radialColors = [
  '#00837A',
  '#90CBCB',
  '#B0DBDD',
  '#C0E3E6',
  '#D0EBEF',
  '#CACAD3',
  '#AFB6B5',
  '#85908F',
  '#4B6260',
  '#00332F',
].map(color => am4core.color(color))

export default radialColors
